import VueRouter from "vue-router";
import IndexPage from "../pages/index";
import DetailPage from "../pages/detail";

export default new VueRouter({
  routes:[
    {
      path:"/",
      name:"index",
      component:IndexPage
    },{
      path:"/detail",
      name:"detail",
      component:DetailPage
    }
  ]
})