var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('router-view'),_c('div',{staticClass:"foot flex"},[_vm._m(0),_c('div',{staticClass:"contact"},[_c('ul',[_vm._m(1),_vm._m(2),_c('li',{staticClass:"flex"},[_vm._m(3),_c('div',{staticClass:"userName"},[_vm._v("Hello_Jacky")]),_c('div',{staticClass:"codeBtn",on:{"click":_vm.pop}},[_c('img',{attrs:{"src":require("./assets/images/codeBtn.png"),"alt":""}})])])])])]),(_vm.isPop)?_c('div',{staticClass:"pop"},[_c('div',{staticClass:"code"},[_c('div',{staticClass:"close",on:{"click":_vm.closePop}},[_c('img',{attrs:{"src":require("./assets/images/close.png"),"alt":""}})]),_vm._m(4),_vm._m(5)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footLogo"},[_c('img',{attrs:{"src":require("./assets/images/footLogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"flex"},[_c('div',{staticClass:"phoneIcon"},[_c('img',{attrs:{"src":require("./assets/images/phoneIcon.png"),"alt":""}})]),_c('div',{staticClass:"phoneText"},[_vm._v("134 0212 6556")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"flex"},[_c('div',{staticClass:"emailIcon"},[_c('img',{attrs:{"src":require("./assets/images/emailIcon.png"),"alt":""}})]),_c('div',{staticClass:"emailText"},[_vm._v("jc@wizard-mul.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wxIcon"},[_c('img',{attrs:{"src":require("./assets/images/wxIcon.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeImg"},[_c('img',{attrs:{"src":require("./assets/images/code.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeTs"},[_c('p',[_vm._v("添加微信")]),_c('p',[_vm._v("获得更多资讯")])])
}]

export { render, staticRenderFns }