var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"about"},[_c('div',{staticClass:"aboutUs"},[_c('img',{attrs:{"src":require("../assets/images/aboutBg.png")}}),_vm._m(0),_c('div',{staticClass:"aboutImg"},[_c('img',{class:{'aboutAnt':_vm.isAnt==1},attrs:{"src":require("../assets/images/aboutImg1.png")}}),_c('img',{class:{'aboutAnt':_vm.isAnt==2},attrs:{"src":require("../assets/images/aboutImg2.png")}})])]),_vm._m(1)]),_c('div',{staticClass:"productList"},[_c('div',{staticClass:"tit"},[_vm._v("我们的服务")]),_c('div',{staticClass:"myServe"},[_c('img',{attrs:{"src":require("../assets/images/serveBg.png")}}),_c('div',{staticClass:"serveList"},[_c('div',{staticClass:"p1",on:{"click":function($event){return _vm.toDetail(1)}}},[_c('div',{staticClass:"productTit"},[_vm._v("新零售派样服务")]),_c('div',{staticClass:"productText"},[_vm._v("为你提供一站式派样服务")]),_c('div',{staticClass:"productText"},[_vm._v("0门槛拥抱新零售派样")]),_vm._m(2)]),_c('div',{staticClass:"p2",on:{"click":function($event){return _vm.toDetail(2)}}},[_c('div',{staticClass:"productTit"},[_vm._v("线上H5、小程序")]),_c('div',{staticClass:"productText"},[_vm._v("提供线上数字营销创意")]),_c('div',{staticClass:"productText"},[_vm._v("品牌小程序对接制作")]),_vm._m(3)]),_c('div',{staticClass:"p3",on:{"click":function($event){return _vm.toDetail(3)}}},[_vm._m(4),_c('div',{staticClass:"productText"},[_vm._v("提供线下活动互动装置")]),_c('div',{staticClass:"productText"},[_vm._v("创意定制开发")]),_vm._m(5)]),_c('div',{staticClass:"p4",on:{"click":function($event){return _vm.toDetail(4)}}},[_vm._m(6),_c('div',{staticClass:"productText"},[_vm._v("传统门店数字化改造升级")]),_c('div',{staticClass:"productText"},[_vm._v("提升消费体验")]),_vm._m(7)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutContainer"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v("互动营销技术整合服务机构")]),_c('div',{staticClass:"aboutText"},[_vm._v(" 上海晶锐拥有一批行业精英和顶尖技术人才，善于洞察用户的潜在需求，提供完善的技支持和后期跟踪回访。从营销创意策划、用户体验设计、技术研发、现场落地执行到后期设备维护，系统升级，上海晶锐为众多知名品牌提供了一站式的数字服务。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('div',{staticClass:"brandTit flex"},[_c('div'),_c('div',[_vm._v("合作品牌")])]),_c('img',{attrs:{"src":require("../assets/images/brand.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('img',{attrs:{"src":require("../assets/images/more.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('img',{attrs:{"src":require("../assets/images/more.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productTit"},[_c('div',[_vm._v("线下快闪、活动")]),_c('div',[_vm._v("互动装置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('img',{attrs:{"src":require("../assets/images/more.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productTit"},[_c('div',[_vm._v("线下门店")]),_c('div',[_vm._v("数字新零售")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('img',{attrs:{"src":require("../assets/images/more.png")}})])
}]

export { render, staticRenderFns }