<template>
  <div class="detail">
    <div class="imgList" v-for="(item,index) in 4" :key="index">
      <img :src="require('../assets/images/detail/'+sId+'/'+index+'.jpg')" alt="">
      <!-- <img src="../assets/images/detail/1/1.jpg" alt=""> -->
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        sId:0
      }
    },
    created(){
      var that=this;
      that.sId=that.$route.query.dIdx;
    }
  }
</script>
<style scoped>
  
</style>