<template>
  <div id="app">
    <router-view/>
    <div class="foot flex">
      <div class="footLogo"><img src="./assets/images/footLogo.png" alt=""></div>
      <div class="contact">
        <ul>
          <li class="flex"><div class="phoneIcon"><img src="./assets/images/phoneIcon.png" alt=""></div><div class="phoneText">134 0212 6556</div></li>
          <li class="flex"><div class="emailIcon"><img src="./assets/images/emailIcon.png" alt=""></div><div class="emailText">jc@wizard-mul.com</div></li>
          <li class="flex"><div class="wxIcon"><img src="./assets/images/wxIcon.png" alt=""></div><div class="userName">Hello_Jacky</div><div class="codeBtn" @click="pop"><img src="./assets/images/codeBtn.png" alt=""></div></li>
        </ul>
      </div>
    </div>
    <div class="pop" v-if="isPop">
      <div class="code">
        <div class="close" @click="closePop"><img src="./assets/images/close.png" alt=""></div>
        <div class="codeImg"><img src="./assets/images/code.png" alt=""></div>
        <div class="codeTs"><p>添加微信</p><p>获得更多资讯</p></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      isPop:false
    }
  },
  methods:{
    pop(){
      this.isPop=true;
    },
    closePop(){
      this.isPop=false;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 12.5rem;
}
.foot{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
  z-index: 20;
  background: #000;
  color: #fff;
  box-sizing: border-box;
  padding: 0 2.125rem;
  justify-content: space-between;
  align-items: center;
}
.footLogo{
  width: 13.8125rem;
  height: 12.5rem;
  line-height: 12.5rem;
}
.contact li{
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.625rem;
}
.contact li div:first-child{
  width: 1.75rem;
  text-align: center;
  margin-right: 0.625rem;
}
.phoneIcon img{
  width: 1rem;
}
.emailIcon img{
  width:1.375rem;
}
.wxIcon img{
  width: 1.75rem;
}
.codeBtn{
  margin-left: 1rem;
}
.codeBtn img{
  width: 1.5625rem;
}
.pop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.71);
  z-index: 50;
}
.code{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 10;
  background: #000;
  padding:6rem 5.375rem 4.25rem;
  color: #fff;
  text-align: center;
}
.close{
  position: absolute;
  top: 1.875rem;
  right: 1.5rem;
  z-index: 20;
  width: 1.8125rem;
}
.codeImg{
  width: 17.0625rem;
}
.codeTs{
  padding-top: 1.25rem;
  font-size: 1.625rem;
  line-height: 2.25rem;
}
</style>
