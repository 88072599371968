<template>
  <div class="container">
    <div class="content">
      <div class="about">
        <div class="aboutUs">
          <img src="../assets/images/aboutBg.png"/>
          <div class="aboutContainer">
            <div class="logo"><img src="../assets/images/logo.png" alt=""></div>
            <div class="title">互动营销技术整合服务机构</div>
            <div class="aboutText">
              上海晶锐拥有一批行业精英和顶尖技术人才，善于洞察用户的潜在需求，提供完善的技支持和后期跟踪回访。从营销创意策划、用户体验设计、技术研发、现场落地执行到后期设备维护，系统升级，上海晶锐为众多知名品牌提供了一站式的数字服务。
            </div>
          </div>
          <div class="aboutImg">
            <img src="../assets/images/aboutImg1.png" :class="{'aboutAnt':isAnt==1}"/>
            <img src="../assets/images/aboutImg2.png" :class="{'aboutAnt':isAnt==2}"/>
          </div>
        </div>
        <div class="brand">
          <div class="brandTit flex">
            <div></div>
            <div>合作品牌</div>
          </div>
          <img src="../assets/images/brand.png"/>
        </div>
      </div>
      <div class="productList">
        <div class="tit">我们的服务</div>
        <div class="myServe">
          <img src="../assets/images/serveBg.png"/>
          <div class="serveList">
            <div class="p1" @click="toDetail(1)">
              <div class="productTit">新零售派样服务</div>
              <div class="productText">为你提供一站式派样服务</div>
              <div class="productText">0门槛拥抱新零售派样</div>
              <div class="more"><img src="../assets/images/more.png"/></div>
            </div>
            <div class="p2" @click="toDetail(2)">
              <div class="productTit">线上H5、小程序</div>
              <div class="productText">提供线上数字营销创意</div>
              <div class="productText">品牌小程序对接制作</div>
              <div class="more"><img src="../assets/images/more.png"/></div>
            </div>
            <div class="p3" @click="toDetail(3)">
              <div class="productTit"><div>线下快闪、活动</div><div>互动装置</div></div>
              <div class="productText">提供线下活动互动装置</div>
              <div class="productText">创意定制开发</div>
              <div class="more"><img src="../assets/images/more.png"/></div>
            </div>
            <div class="p4" @click="toDetail(4)">
              <div class="productTit">
                <div>线下门店</div>
                <div>数字新零售</div>
              </div>
              <div class="productText">传统门店数字化改造升级</div>
              <div class="productText">提升消费体验</div>
              <div class="more"><img src="../assets/images/more.png"/></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer">
        <img src="{{imgUrl}}/project/businessCard/images/foot.jpg"/>
        <div class="footBtnContent">
          <div class="footName">{{businessCard.userName}}</div>
          <div class=" footBtnList flex">
            <div bindtap="phone"><img src="{{imgUrl}}/project/businessCard/images/phoneBtn2.png"/></div>
            <div bindtap="addWx"><img src="{{imgUrl}}/project/businessCard/images/wxBtn2.png"/></div>
            <div><button plain="true" open-type="share"><image src="{{imgUrl}}/project/businessCard/images/shareBtn2.png"/></button></div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data(){
    return{
      isAnt:3,
      timer:null
    }
  },
  mounted(){
    var that=this;
    that.timer=setInterval(()=>{
      that.isAnt=that.isAnt==1?2:1;
    },5000)
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods:{
    toDetail(e) {
      var sId=e,that=this;
      that.$router.push({name:"detail",query:{dIdx:sId}});
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content{
  width: 100%;
  padding-top: 5.625rem;
}
.about{
  background: #000;
}
.aboutUs{
  width: 44.3125rem;
  padding-left: 0.18rem;
  position: relative;
}
.aboutContainer{
  position: absolute;
  top: 5.625rem;
  left: 3.75rem;
  z-index: 10;
}
.title{
  color: #fecc44;
  font-size: 2.5625rem;
  line-height: 3.75rem;
  height: 3.75rem;
  letter-spacing: 0.1875rem;
}
.aboutContainer .logo img{
  width: 9.375rem;
}
.logo{
  margin-bottom: 1.25rem;
}
.aboutText{
  font-size: 1.4375rem;
  color: #fff;
  margin-top: 5rem;
  width: 32.5rem;
  letter-spacing: 0.25rem;
  line-height: 2.875rem;
}
.aboutImg{
  position: absolute;
  width: 18.3125rem;
  top: 3.75rem;
  right: 0;
  z-index: 5;
}
.aboutImg img{
  position: absolute;
  top: 0;
  left: 0;
}
.brand{
  padding-top: 1.75rem;
  padding-bottom: 2.875rem;
  position: relative;
}
.brandTit{
  position: absolute;
  top: 1.75rem;
  left: 0;
  z-index: 1;
  color: #e4a430;
  font-weight: bold;
  font-size: 1.875rem;
  letter-spacing: 0.125rem;
  align-items: center;
}
.brandTit div:first-child{
  width: 2rem;
  height: 0.125rem;
  box-sizing: border-box;
  border-top: 0.125rem solid #f5c33e;
}
.brandTit div:last-child{
  width: 18.75rem;
  height: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
  box-sizing: border-box;
  border: 0.125rem solid #f5c33e;
  border-radius: 4.5rem;
}
.brand img{
  width: 44.8125rem;
}
.productList{
  background: #e4e4e4;
  padding-top: 3.125rem;
  padding-bottom: 4.25rem;
}
.tit{
  width: 18.75rem;
  height: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
  margin:0 auto 3.125rem;
  box-sizing: border-box;
  border: 0.125rem solid #000;
  font-size: 1.875rem;
  letter-spacing: 0.125rem;
  border-radius: 4.5rem;
  font-weight: bold;
}
.myServe{
  text-align: center;
  position: relative;
  padding-bottom: 0.875rem;
}
.myServe img{
  width: 34.875rem;
}
.serveList{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 34.875rem;
  height: 100%;
}
.productTit{
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: 2rpx;
}
.productText{
  font-size: 1.25rem;
  height: 1.75rem;
  line-height: 1.75rem;
}
.serveList>div{
  position: absolute;
  z-index: 10;
}
.p1{
  top: 0;
  left: 0.625rem;
}
.p2{
  top: 0;
  right: 0.375rem;
}
.p3{
  bottom: 0;
  left: 0.625rem;
}
.p4{
  bottom: 0;
  right: 0.625rem;
}
.p1 .productTit,.p2 .productTit{
  height: 7.25rem;
  box-sizing: border-box;
}
.p1 .productTit,.p2 .productTit{
  padding-top: 3rem;
}
.p3 .productTit,.p4 .productTit{
  margin-bottom: 1.375rem;
}
.more{
  text-align: center;
  margin-top: 2.375rem;
}
.more img{
  width: 2.75rem;
}
.aboutAnt{
  animation: ant1 1s linear;
  -webkit-animation: ant1 1s linear;
  -moz-animation: ant1 1s linear;
  -ms-animation: ant1 1s linear;
  -o-animation: ant1 1s linear;
  opacity: 1;
  z-index: 2;
}
@keyframes ant1{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
@-webkit-keyframes ant1{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
@-moz-keyframes ant1{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
@-ms-keyframes ant1{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
@-o-keyframes ant1{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}
</style>
